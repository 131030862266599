import { Route, Switch } from 'react-router-dom';
import { Homepage } from './pages/Homepage/Homepage';
import DataSources from 'pages/DataSources/DataSources';
import Subscribers from 'pages/Subscribers/Subscribers';
import ShotViewer from 'pages/ShotViewer/ShotViewer';
import Config from 'pages/Config/Config';

export const DefaultRoutes = {
  Root: '/',
  FrontPage: '/Homepage',
  TestPage: '/Testpage',
  TableExample: '/TableExample',
  DataSources: '/DataSources',
  Subscribers: '/Subscribers',
  ShotViewer: '/Shotviewer',
  Config: '/Config',
};

export const routes = (
  <Switch>
    <Route exact path={DefaultRoutes.Root} component={Homepage} />
    <Route exact path={DefaultRoutes.FrontPage} component={Homepage} />
    <Route exact path={DefaultRoutes.DataSources} component={DataSources} />
    <Route exact path={DefaultRoutes.Subscribers} component={Subscribers} />
    <Route exact path={DefaultRoutes.ShotViewer} component={ShotViewer} />
    <Route exact path={DefaultRoutes.Config} component={Config} />
  </Switch>
);
