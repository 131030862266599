import './ShotVisualizer.scss';

type Settings = {
  view?: 'side' | 'top';
  gridSize: number;
  markers?: {
    wind?: 'show' | 'hide' | 'highlight';
    maxHeight?: 'show' | 'hide' | 'highlight';
    lastData?: 'show' | 'hide' | 'highlight';
  };
};

export const ShotVisualizer = ({
  shotData,
  settings,
  highlightMetric,
}: {
  shotData: any;
  settings: Settings;
  highlightMetric: string | null;
}) => {
  const coordToPixel = (coord: number, offset = 0) => {
    return coord * scale + offset;
  };

  // One pixel is one meter multiplied by the scale
  const svgWidth = 600;
  const svgHeight = 200;
  const scale = 1.5;

  const axisNames = ['X', 'Y', 'Z'];
  // The two axes used to draw the grid (X, Y)
  // 0 is X, 1 is Y, 2 is Z
  let axes = [0, 1];

  // Wind marker position
  const wmStartX = svgWidth * 0.95;
  const wmStartY = svgHeight * 0.1;

  // Pixel offset on the svg element
  let startX = svgWidth * 0.05;
  let startY = svgHeight * 0.8;

  // Switch axes for top view
  if (settings.view === 'top') {
    axes = [0, 2];
    startY = svgHeight / 2;
  }

  const canDraw = () => {
    return shotData.ballTrajectory && shotData.ballTrajectory.length > 0;
  };

  const createGridLinesV = () => {
    let lines: number[] = [];
    const vGridAmount = Math.floor(svgWidth / (settings.gridSize * scale));

    for (let i = 0; i <= vGridAmount; i++) {
      lines.push(i * settings.gridSize * scale + startX);
    }

    return lines;
  };

  const createGridLinesH = () => {
    let lines: number[] = [];

    const hGridAmount = Math.floor(svgHeight / (settings.gridSize * scale));

    for (let i = 0; i <= hGridAmount; i++) {
      if (settings.view === 'top') {
        lines.push(svgHeight / 2 + startY - i * settings.gridSize * scale);
      } else {
        lines.push(startY - i * settings.gridSize * scale);
      }
    }

    return lines;
  };

  const getWindDirection = () => {
    // returen the angle of the wind 0-360
    if (!shotData.windVelocity) {
      return 0;
    }
    const windVelocity = shotData.windVelocity;
    return Math.atan2(windVelocity[axes[1]], windVelocity[axes[0]]) * (180 / Math.PI);
  };

  const evaluatePolynomial = (coeffs, t) => {
    return coeffs.reduce((acc, coeff, index) => acc + coeff * Math.pow(t, index), 0);
  };

  const get2DPoints = () => {
    // Convert the fitted polynomial to a 2D path
    let newPath: number[][] = [];

    if (!shotData.ballTrajectory) {
      return [];
    }

    const ballTrajectory = shotData.ballTrajectory[0];
    const ballTrajectoryTime = ballTrajectory.timeInterval;

    let timeStart = ballTrajectoryTime[0];
    let timeEnd = ballTrajectoryTime[1];
    let steps = 30;

    for (let i = 0; i <= steps; i++) {
      let t = timeStart + (timeEnd - timeStart) * (i / steps);

      let x = evaluatePolynomial(ballTrajectory.xFit, t);
      let y = evaluatePolynomial(ballTrajectory.yFit, t);
      let z = evaluatePolynomial(ballTrajectory.zFit, t);

      newPath.push([x, y, z]);
    }

    let path2D: number[][] = [];

    if (newPath.length === 0) {
      return [];
    }

    for (let i = 0; i < newPath.length; i++) {
      let x = newPath[i][axes[0]] - newPath[0][axes[0]];

      let y = -(newPath[i][axes[1]] - newPath[0][axes[1]]);

      // We have to invert the Y axis when in top view in order to render the path correctly
      if (settings.view === 'top') {
        y = newPath[i][axes[1]] - newPath[0][axes[1]];
      }

      path2D.push([x, y]);
    }

    return path2D;
  };

  const gridLinesV: number[] = createGridLinesV();
  const gridLinesH: number[] = createGridLinesH();
  const pathData = get2DPoints().map((point) => `${point[0] * scale + startX},${point[1] * scale + startY}`);

  return (
    <div>
      <svg
        width={svgWidth}
        height={svgHeight}
        className={`shotSvg --${settings.view}View`}
        style={{
          aspectRatio: `${svgWidth} / ${svgHeight}`,
        }}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        preserveAspectRatio='xMidYMin slice'
      >
        {(canDraw() && (
          <>
            <g className='gridX'>
              {gridLinesV.map((lineX) => (
                <line className='gridVLine' key={lineX} x1={lineX} y1={0} x2={lineX} y2={svgHeight} />
              ))}
            </g>
            <g className='gridY'>
              {gridLinesH.map((lineY) => (
                <line className='gridHLine' key={lineY} x1={0} y1={lineY} x2={svgWidth} y2={lineY} />
              ))}
            </g>

            {shotData.windVelocity && settings.markers?.wind !== 'hide' && (
              <g className='windDirection'>
                <polygon
                  points='0,20 10,20 5,0'
                  fill='black'
                  transform={`translate(${wmStartX} ${wmStartY}) rotate(${getWindDirection()} 5 10)`}
                />
              </g>
            )}

            <line className='gridMarker --center' x1={0} y1={startY} x2={svgWidth} y2={startY} />

            {settings.view === 'side' && (
              <line
                className={`gridMarker --maxHeight ${highlightMetric === 'maxHeight' ? '--highlight' : ''}`}
                x1={coordToPixel(shotData.maxHeightRange, startX)}
                y1={startY}
                x2={coordToPixel(shotData.maxHeightRange, startX)}
                y2={coordToPixel(-shotData.maxHeight, startY)}
              />
            )}

            <line
              className={`gridMarker --lastData ${highlightMetric === 'lastData' ? '--highlight' : ''}`}
              x1={startX}
              y1={startY}
              x2={coordToPixel(shotData.lastData, startX)}
              y2={startY}
            />

            <line
              className={`gridMarker --carry ${highlightMetric === 'carry' ? '--highlight' : ''}`}
              x1={startX}
              y1={startY}
              x2={coordToPixel(shotData.carry, startX)}
              y2={startY}
            />

            {settings.view === 'top' && (
              <line
                className={`gridMarker --carrySide ${highlightMetric === 'carrySide' ? '--highlight' : ''}`}
                x1={coordToPixel(shotData.carry, startX)}
                y1={coordToPixel(0, startY)}
                x2={coordToPixel(shotData.carry, startX)}
                y2={coordToPixel(shotData.carrySide, startY)}
              />
            )}

            <g className='legend'>
              <text x={12} y={svgHeight / 2 + 4} className='axisLegendV'>
                {axisNames[axes[1]]}
              </text>
              <text x={svgWidth / 2} y={svgHeight - 8} className='axisLegendH'>
                {axisNames[axes[0]]}
              </text>

              <defs>
                <pattern id='stripes' patternUnits='userSpaceOnUse' width='10' height='10' patternTransform='rotate(45)'>
                  <line x1='0' y='0' x2='0' y2='10' stroke='black' strokeWidth='10' />
                </pattern>
              </defs>

              <text x={(settings.gridSize / 2) * scale + startX} y={svgHeight - 15} dominantBaseline='middle' textAnchor='middle'>
                {settings.gridSize}m
              </text>

              <rect
                className='settings.gridSizeLegend'
                fill='url(#stripes)'
                x={startX}
                y={svgHeight - 8}
                width={settings.gridSize * scale}
                height={5}
              />

              <path d={`M ${pathData.join(' L ')}`} fill='none' />
            </g>
          </>
        )) || (
          <text x={svgWidth / 2} y={svgHeight / 2} dominantBaseline='middle' textAnchor='middle' className='noData'>
            No trajectory data
          </text>
        )}
      </svg>
    </div>
  );
};
