import { ShotVisualizer } from 'components/ShotVisualizer/ShotVisualizer';
import { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { useStores } from 'index';
import { Grid, Paper, Typography, Snackbar, Switch } from '@trackman/web-shared-components';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import './ShotViewer.scss';
import { ShotsService, Hole, ListItemShot } from 'services/ShotsService';

interface Metric {
  attribute: string;
  internalName: string;
  unitType: string;
}

const ShotViewer = () => {
  const metrics: Metric[] = [
    { attribute: 'Apex', internalName: 'maxHeight', unitType: 'distance' },
    { attribute: 'Ball speed', internalName: 'ballSpeed', unitType: 'speed' },
    { attribute: 'Carry', internalName: 'carry', unitType: 'distance' },
    { attribute: 'Club speed', internalName: 'clubSpeed', unitType: 'speed' },
    { attribute: 'Last data', internalName: 'lastData', unitType: 'distance' },
    { attribute: 'Launch angle', internalName: 'launchAngle', unitType: 'angle' },
    { attribute: 'Side', internalName: 'carrySide', unitType: 'distance' },
    { attribute: 'Spin rate', internalName: 'spinRate', unitType: 'spin' },
  ];
  const [gridSize, setGridSize] = useState(25);
  const [holeNumber, setHoleNumber] = useState(-1);
  const [selectedDate, setSelectedDate] = useState('');
  const [shotListingMetric, setShotListingMetric] = useState('carry');
  const [selectedShot, setSelectedShot] = useState('');
  const [shotData, setShotData] = useState<any | null>(null);
  const [selectedUnitFormat, setSelectedUnitFormat] = useState('US');
  const unitsStore = useStores().unitsStore;
  const [holeSelectOpts, setHoleSelectOpts] = useState<Hole[]>([]);
  const [shotSelectOpts, setShotSelectOpts] = useState<ListItemShot[]>([]);
  const [highlightMetric, setHighlightMetric] = useState('');
  const [useAggregatedValues, setUseAggregatedValues] = useState(false);

  const { notificationStore } = useStores();
  const shotsService = useMemo(() => new ShotsService(notificationStore), [notificationStore]);

  useEffect(() => {
    shotsService.getHoles().then((holes) => {
      // pad holes with empty dates array so we always have 18 holes
      let allHoles: Hole[] = [];

      for (let i = 0; i < 18; i++) {
        let hole = holes.find((hole) => hole.holeNumber === i + 1);
        if (!hole) {
          allHoles.push({ holeNumber: i + 1, dates: [] });
        } else {
          allHoles.push(hole);
        }
      }

      setHoleSelectOpts(allHoles);

      // Default to the first returned hole if we have it
      if (holes.length > 0) {
        setHoleNumber(holes[0].holeNumber);
      }
    });

    // Get the shotId from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const shotId = urlParams.get('shotId');

    if (shotId) {
      // Perform the fetch operation if the "id" parameter is set
      shotsService.getShot(shotId).then((shot) => {
        setShotData(shot.finalMeasurement);
        setShotSelectOpts([shot.finalMeasurement]);
        setSelectedShot(shotId);
      });
    }
  }, [shotsService]);

  const getShotListMetric = (shot: ListItemShot) => {
    if (shot.hasOwnProperty(shotListingMetric)) {
      let metricUnit = metrics.find((metric) => metric.internalName === shotListingMetric);
      let metricUnitType = metricUnit?.unitType;
      let metricValue = shot[shotListingMetric];
      let formatted = unitsStore.getFormattedMetric(metricValue, selectedUnitFormat, metricUnitType);

      if (formatted && formatted.value > -1) {
        return `${formatted.value.toFixed(2)} ${formatted.unit}`;
      } else {
        return 'N/A';
      }
    }
  };

  const intesifyMetric = (metric: string) => {
    setHighlightMetric(metric);
  };

  const getShotMetric = (shotMetric) => {
    let shotDataValue = shotData?.[shotMetric.internalName];
    let convertedMetrics = unitsStore.getFormattedMetric(shotDataValue, selectedUnitFormat, shotMetric.unitType);

    return (
      <>
        <strong>{shotMetric.attribute}</strong>
        {(convertedMetrics?.value && (
          <p>
            {convertedMetrics.value.toFixed(2)}
            <span>{convertedMetrics.unit}</span>
          </p>
        )) || <p className='--notAvailable'>N/A</p>}
      </>
    );
  };

  const handleHoleChange = (newValue: number | string) => {
    // Make sure we have a number
    let holeNumberInt = parseInt(newValue as string);
    setHoleNumber(holeNumberInt);
    setSelectedDate('');
    setSelectedShot('');
  };

  const handleDateChange = (event: SelectChangeEvent) => {
    setSelectedDate(event.target.value as string);
    setSelectedShot('');
    updateShotData('', useAggregatedValues);

    shotsService.getShots(holeNumber, event.target.value).then((shots) => {
      setShotSelectOpts(shots);
    });
  };

  const availableDates = (holeNum) => {
    const hole = holeSelectOpts.find((hole) => hole.holeNumber === holeNum);
    if (!hole) {
      return [];
    }

    const uniqueDates = hole.dates.map((dateString) => {
      // Extract the date portion (YYYY-MM-DD)
      return dateString.split('T')[0];
    });

    // Step 2: Remove duplicates
    const uniqueDateArray = Array.from(new Set(uniqueDates));

    return uniqueDateArray || [];
  };

  const updateShotData = (shotId: string, useAggregated: boolean) => {
    if (!shotId) {
      setShotData(null);
      return;
    }

    shotsService.getShot(shotId).then((shot) => {
      // Depending on the selection of Aggregated values vs. final Measurement, we select the correct obect
      if (useAggregated) {
        setShotData(shot.aggregatedLiveMeasurements);
      } else {
        setShotData(shot.finalMeasurement);
      }
    });
  };

  const handleShotChange = (event: SelectChangeEvent) => {
    setSelectedShot(event.target.value as string);
    updateShotData(event.target.value as string, useAggregatedValues);
    // set url parameter
    window.history.pushState({}, '', `/shotviewer?shotId=${event.target.value}`);
  };

  const handleAggregatedValsChange = (event) => {
    setUseAggregatedValues(event.target.checked);
    updateShotData(selectedShot, event.target.checked);
  };

  const handleGridSizeChange = (event: SelectChangeEvent) => {
    setGridSize(parseInt(event.target.value) as number);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs={12} md>
            <FormControl>
              <InputLabel id='holeSelect'>Hole</InputLabel>
              <Select
                labelId='holeSelect'
                id='holeSelectElm'
                label='Hole'
                value={holeNumber}
                style={{ minWidth: 150 }}
                onChange={(evt) => handleHoleChange(evt.target.value)}
              >
                <MenuItem value='-1' disabled={true}>
                  Select hole
                </MenuItem>
                {holeSelectOpts &&
                  holeSelectOpts.map((option) => (
                    <MenuItem key={option.holeNumber} value={option.holeNumber} disabled={option.dates.length === 0}>
                      Hole {option.holeNumber}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='dateSelectElm'>Date</InputLabel>
              <Select
                labelId='dateSelect'
                id='dateSelectElm'
                style={{ minWidth: 150 }}
                label='Date'
                value={selectedDate}
                onChange={handleDateChange}
              >
                <MenuItem value='' disabled={true}>
                  Select date
                </MenuItem>
                {availableDates(holeNumber).map((date, idx) => (
                  <MenuItem key={idx} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='shotSelect'>Shot ({shotListingMetric})</InputLabel>
              <Select
                labelId='shotSelect'
                id='shotSelectElm'
                style={{ minWidth: 200 }}
                label={'Shot (' + shotListingMetric + ')'}
                value={selectedShot}
                onChange={handleShotChange}
              >
                <MenuItem value='' disabled={true}>
                  Select shot
                </MenuItem>
                {shotSelectOpts.map((option, idx) => (
                  <MenuItem key={option.id} value={option.id}>
                    {dayjs(option.time).format('HH:MM:ss')} - {getShotListMetric(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Switch
              label='TV values'
              checked={useAggregatedValues}
              onChange={(evt) => {
                handleAggregatedValsChange(evt);
              }}
              style={{ height: '100%', marginLeft: '1rem' }}
            />
          </Grid>

          <Grid item xs={12} lg='auto'>
            <FormControl>
              <InputLabel id='shotListingMetric'>Shot listing metric</InputLabel>
              <Select
                labelId='shotListingMetric'
                id='shotListingMetricElm'
                style={{ minWidth: 150 }}
                value={shotListingMetric}
                label='Shot listing metric'
                onChange={(e) => setShotListingMetric(e.target.value)}
              >
                {metrics.map((metric, index) => (
                  <MenuItem key={index} value={metric.internalName}>
                    {metric.attribute}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='unitSelect'>Units</InputLabel>
              <Select
                labelId='unitSelect'
                id='unitSelectElm'
                label='Units'
                autoWidth={true}
                value={selectedUnitFormat}
                onChange={(e) => setSelectedUnitFormat(e.target.value)}
              >
                {unitsStore.getUnitFormats().map((unit) => (
                  <MenuItem key={unit.value} value={unit.label}>
                    {unit.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='gridSelect'>Grid size</InputLabel>
              <Select
                labelId='gridSelect'
                autoWidth={true}
                id='gridSelect'
                value={gridSize.toString()}
                label='Grid size'
                onChange={handleGridSizeChange}
              >
                <MenuItem value='10'>10m</MenuItem>
                <MenuItem value='25'>25m</MenuItem>
                <MenuItem value='50'>50m</MenuItem>
                <MenuItem value='100'>100m</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {shotData && (
          <>
            <Grid item container md={6} lg={4}>
              <Grid item xs={12} mb={2}>
                <Paper style={{ padding: '1rem', height: '100%' }} className='shotVisualizer'>
                  <ShotVisualizer shotData={shotData} settings={{ view: 'side', gridSize: gridSize }} highlightMetric={highlightMetric} />
                  <Typography variant='body bold' style={{ textAlign: 'center' }}>
                    Side view
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: '1rem', height: '100%' }} className='shotVisualizer'>
                  <ShotVisualizer shotData={shotData} settings={{ view: 'top', gridSize: gridSize }} highlightMetric={highlightMetric} />
                  <Typography variant='body bold' style={{ textAlign: 'center' }}>
                    Top view
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid item container md={6} lg={8} spacing={2}>
              {metrics.map((metric, index) => (
                <Grid
                  item
                  key={index}
                  xs={6}
                  md={4}
                  lg={3}
                  onMouseEnter={() => intesifyMetric(metric.internalName)}
                  onMouseLeave={() => intesifyMetric('')}
                >
                  <Paper className='metricPaper'>{getShotMetric(metric)}</Paper>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Paper className='metricPaper'>
                  <strong>Tee position</strong>
                  <p>
                    {shotData.teePosition ? `${shotData.teePosition[0]}, ${shotData.teePosition[1]}, ${shotData.teePosition[2]}` : 'N/A'}
                  </p>
                </Paper>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Typography variant='body bold' style={{ marginTop: '1rem' }}>
                Shot time: {dayjs(shotData.time).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </Grid>
          </>
        )}
        {!shotData && (
          <Grid item xs={12}>
            <Snackbar color='info' variant='outlined'>
              No shot data selected
            </Snackbar>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ShotViewer;
