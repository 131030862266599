import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import './Subscribers.scss';
import Chip from '@trackman/web-shared-components/components/Chip';
import Tooltip from '@trackman/web-shared-components/components/Tooltip';
import Colors from '@trackman/web-shared-components/styles/Colors';
import StatsService from 'services/StatsService';
import { ReactComponent as WarningIcon } from 'assets/images/icons/figma/warning.svg';
import { useStores } from 'index';

type Subscription = {
  title?: string;
  locationId: number;
  topic: string;
  subTopic: string;
  holeNumbers: string[];
};

type Row = {
  id: string;
  ip: string;
  slowShotMessageCount: number;
  subscriptions: Subscription[];
};

// TODO: Get the time interval from the BE
const slowMessageTimeInterval = 60;

let columns = [
  { field: 'id', headerName: 'ID', type: 'string', width: 320 },
  {
    field: 'slowShotMessageCount',
    headerName: 'Warnings',

    renderCell: (params: GridRenderCellParams<any, Row>) =>
      params.value > 0 ? (
        <Tooltip
          title={`##${params.value} shot${params.value > 1 ? 's' : ''} marked as slow within the last ${slowMessageTimeInterval} minutes.##`}
        >
          <WarningIcon className='warningIcon' />
        </Tooltip>
      ) : (
        ''
      ),
  },
  { field: 'ip', headerName: 'IP / Domain', width: 200 },
  {
    field: 'subscriptions',
    headerName: 'Subscriptions',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, Row>) => {
      return params.value.map((sub: Subscription, idx: number) => {
        return (
          <Tooltip title={`Hole${sub.holeNumbers[0] === 'all' || sub.holeNumbers.length > 1 ? 's' : ''}:  ${sub.holeNumbers}`} key={idx}>
            <div className='chipWrapper'>
              <Chip sx={{ backgroundColor: Colors.orange }} className='topicChip' label={`${sub.title}`} key={idx} />
            </div>
          </Tooltip>
        );
      });
    },
  },
];

const formatSubscriptions = (subscriptions: any[]): Subscription[] => {
  let subArray: Subscription[] = [];

  // Add a title to the subscription with the combined topic / subtopic
  for (let i = 0; i < subscriptions.length; i++) {
    let found = false;
    let subTopic = subscriptions[i].subTopic === 'None' ? 'All' : subscriptions[i].subTopic;

    for (let j = 0; j < subArray.length; j++) {
      if (subArray[j].title === subscriptions[i].topic + ' / ' + subTopic) {
        subArray[j].holeNumbers.push(subscriptions[i].holeNumber ? subscriptions[i].holeNumber : 'all');
        found = true;
        break;
      }
    }

    if (!found) {
      subArray.push({
        title: subscriptions[i].topic + ' / ' + subTopic,
        locationId: subscriptions[i].locationId,
        topic: subscriptions[i].topic,
        subTopic: subTopic,
        holeNumbers: [subscriptions[i].holeNumber ? subscriptions[i].holeNumber : 'all'],
      });
    }
  }

  return subArray;
};

export const Subscribers = observer(() => {
  const [rows, setRows] = useState<Row[]>([]);
  const { notificationStore } = useStores();

  useEffect(() => {
    const subsService = new StatsService(notificationStore);
    subsService.addSignalREventHandler('subscriber', 'GetAll', (message: { id: string; ip: string; subscriptions: any[] }[]) => {
      let subRow: Row[] = [];

      for (let i = 0; i < message.length; i++) {
        const newRow: Row = {
          id: message[i].id,
          ip: message[i].ip,
          slowShotMessageCount: 0, // TODO: Get the slow shot message count from the BE
          subscriptions: formatSubscriptions(message[i].subscriptions),
        };

        subRow.push(newRow);
      }

      setRows(subRow);
    });

    return () => {
      subsService.stopSignalRConnection();
    };
  }, [notificationStore]);

  return (
    <div className='tm-subscribers' style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }],
          },
        }}
      />
    </div>
  );
});

export default Subscribers;
