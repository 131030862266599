import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import './Footer.scss';
import { useStores } from 'index';

export const Footer = observer(() => {
  const { statusStore } = useStores();

  return (
    <Paper className='tm-footer' variant='outlined' square={true}>
      <Grid container alignContent='center' justifyContent='center' height={'100%'}>
        <Grid item xs={6}>
          <Typography color='textSecondary' variant='body2' align='center'>
            Data Processor version: {statusStore.system.version}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
});
