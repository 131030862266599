import { FC } from 'react';
import { Sidebar } from 'components/SideBar/Sidebar';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import 'styles/index.scss';
import { observer } from 'mobx-react-lite';
import ThemeProvider from '@trackman/web-shared-components/styles/Theme';
import Grid from '@trackman/web-shared-components/components/Grid';
import Paper from '@trackman/web-shared-components/components/Paper';
import { Notifications } from 'components/Notifications';

type Props = {
  children?: React.ReactNode;
};

export const App: FC<Props> = observer((props) => {
  return (
    <ThemeProvider>
      <Grid container>
        <Grid item xs={12}>
          <Paper square={true} padding={'0'}>
            <Header />
          </Paper>
        </Grid>
        <Grid item xs='auto' className='sidebarWrapper'>
          <Sidebar />
        </Grid>
        <Grid item xs={true} p={3}>
          <div>{props.children}</div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
      <Notifications />
    </ThemeProvider>
  );
});

export default App;
