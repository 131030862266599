import * as signalR from '@microsoft/signalr';
import { SignalRConfig } from 'constants/SignalR';

class StatsService {
  private hubConnection: signalR.HubConnection | null = null;
  private notificationStore: any;

  constructor(notificationStore: any) {
    this.notificationStore = notificationStore;
  }

  // Initialize SignalR connection
  startSignalRConnection(targetHub: string, onConnectionEstablished?: (connection: StatsService) => void) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.None)
      .withUrl(SignalRConfig.baseUrl + targetHub)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        if (onConnectionEstablished) {
          onConnectionEstablished(this);
        }
      })
      .catch((err) => {
        console.error('Error connecting to SignalR Hub:', err);
        this.notificationStore.addToast('Error', 'Failed to connect to "' + targetHub + '" SignalR Hub ', 'error');
      });
  }

  stopSignalRConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }

  // Handle SignalR events
  addSignalREventHandler(targetHub: string, eventName: string, handler: (...args: any[]) => void) {
    // If the connection is not initialized, initialize it
    if (!this.hubConnection) {
      this.startSignalRConnection(targetHub, (connection: StatsService) => {
        if (connection && connection.hubConnection) {
          connection.hubConnection.off(eventName);
          connection.hubConnection.on(eventName, handler);
        }
      });
    } else {
      this.hubConnection.off(eventName);
      this.hubConnection.on(eventName, handler);
    }
  }
}

export default StatsService;
