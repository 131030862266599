import { observer } from 'mobx-react-lite';
import './Header.scss';
import Paper from '@mui/material/Paper';
import { ReactComponent as TrackmanLogo } from 'assets/images/icons/Trackman_logo--dark.svg';

export const Header = observer(() => {
  return (
    <Paper className='tm-header' variant='outlined' square={true}>
      <TrackmanLogo />
    </Paper>
  );
});
