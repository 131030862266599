import axios, { AxiosInstance, AxiosResponse } from 'axios';

const baseURL = '/api/';

export type Hole = {
  holeNumber: number;
  dates: string[];
};

export type ListItemShot = {
  id: string;
  time: string;
  maxHeight: number;
  ballSpeed: number;
  carry: number;
  lastData: number;
  launchAngle: number;
  carrySide: number;
  spinRate: number;
};

type MeasurementDataQuality = {
  flatShotWarning: boolean;
  movementAfterStrokeCalibration: boolean;
  reducedAccuracy?: any[];
  simulatedFlatShot?: boolean;
  potentialGhost?: boolean;
};

type TrajectoryFit = number[];

type BallTrajectory = {
  spinRateEffectiveFit: TrajectoryFit;
  trackingPercentage: number;
  kind: string;
  xFit: TrajectoryFit;
  yFit: TrajectoryFit;
  zFit: TrajectoryFit;
  spinRateFit: TrajectoryFit;
  timeInterval: [number, number];
  validTimeInterval: [number, number];
  measuredTimeInterval: [number, number];
};

type NormalizedTrajectory = {
  valid: boolean;
};

type AggregatedLiveMeasurements = {
  launchValid: boolean;
  spinRateMethod: string;
  dataQuality: MeasurementDataQuality;
  id: string;
  time: string;
  kind: string;
  teePosition: [number, number, number];
  launchDirection: number;
  ballSpeed: number;
  launchAngle: number;
  spinRate: number;
  maxHeight: number;
};

type FinalMeasurement = {
  launchValid: boolean;
  configurationId: string;
  bayId: string;
  bayName: string;
  bayType: string;
  bayPosition: [number, number, number];
  ballVelocity: [number, number, number];
  maxHeightSide: number;
  maxHeightRange: number;
  maxHeightValid: boolean;
  lastTime: number;
  lastLandingAngle: number;
  lastLandingSpeed: number;
  lastRange: number;
  lastHeight: number;
  lastSide: number;
  lastValid: boolean;
  landingSpeed: number;
  landingPosition: [number, number, number];
  flatRange: number;
  flatSide: number;
  flatValid: boolean;
  spinRateLanding: number;
  spinRateMethod: string;
  spinRateLandingMethod: string;
  windVelocity: [number, number, number];
  dataQuality: MeasurementDataQuality;
  ballTrajectory: BallTrajectory[];
  normalizedTrajectory: NormalizedTrajectory;
  actualValid: boolean;
  id: string;
  time: string;
  kind: string;
  teePosition: [number, number, number];
  launchDirection: number;
  ballSpeed: number;
  launchAngle: number;
  spinAxis: number;
  spinRate: number;
  maxHeight: number;
  carry: number;
  carrySide: number;
  landingAngle: number;
  hangTime: number;
  lastData: number;
  curve: number;
};

export type ShotData = {
  aggregatedLiveMeasurements: AggregatedLiveMeasurements;
  finalMeasurement: FinalMeasurement;
};

export class ShotsService {
  private axiosInstance: AxiosInstance;
  private notificationStore: any;

  constructor(notificationStore: any) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: 10000, // 10 seconds timeout
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.notificationStore = notificationStore;
  }

  public async getHoles(): Promise<Hole[]> {
    try {
      const response: AxiosResponse<Hole[]> = await this.axiosInstance.get(`/shot/hole-info`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
      throw error;
    }
  }

  public async getShots(holeNumber: number, date: string): Promise<ListItemShot[]> {
    try {
      const response: AxiosResponse<ListItemShot[]> = await this.axiosInstance.get(`/shot/shot-metrics/${holeNumber}/${date}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
      throw error;
    }
  }

  public async getShot(id: string): Promise<ShotData> {
    try {
      const response: AxiosResponse<ShotData> = await this.axiosInstance.get(`/shot/measurements/${id}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
      throw error;
    }
  }

  // Utility function to handle Api errors
  private handleApiError(error: any) {
    if (axios.isAxiosError(error)) {
      // Axios-specific error handling
      console.error('Api Error:', error.response?.data || error.message);
      this.notificationStore.addToast('Error', error.response?.data || error.message, 'error');
    } else {
      // Non-Axios-specific error handling
      console.error('Unexpected Error:', error);
      this.notificationStore.addToast('Error', 'Unexpected error occurred', 'error');
    }
  }
}
