import { makeAutoObservable, runInAction } from 'mobx';
import { ToastProps } from '@trackman/web-shared-components/components/Toast';

export class NotificationsStore {
  private maxToasts: number;
  private duration: number;
  toasts: ToastProps[] = [];

  constructor() {
    makeAutoObservable(this);
    this.duration = 10000;
    this.maxToasts = 3;
  }

  handleOnClose = async (id: string) => {
    runInAction(() => {
      this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
    });
  };

  addToast = async (header: string, message: string, severity: 'info' | 'success' | 'error' | 'warning') => {
    runInAction(() => {
      const id = Date.now().toString();

      if (this.toasts.length >= this.maxToasts) this.toasts.shift();

      this.toasts.push({
        severity: severity,
        header: header,
        message: message,
        id: id,
      });

      new Promise((resolve) => {
        setTimeout(() => {
          runInAction(() => {
            resolve(id);
            this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
          });
        }, this.duration);
      });
    });
  };
}
