import { observer } from 'mobx-react-lite';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { Graph } from 'components/Graph/Graph';
import { Counter } from 'components/Counter/Counter';
import { useState, useEffect } from 'react';
import StatsService from 'services/StatsService';
import { useStores } from 'index';
import dayjs from 'dayjs';

export const Homepage = observer(() => {
  const [shotCount, setShotCount] = useState(0);
  const [connectedDataSourcesCount, setConnectedDataSourcesCount] = useState(0);
  const [connectedSubsCount, setConnectedSubsCount] = useState(0);
  const { statusStore, notificationStore } = useStores();

  useEffect(() => {
    const graphService = new StatsService(notificationStore);
    const subsService = new StatsService(notificationStore);
    const trackerService = new StatsService(notificationStore);
    const shotsService = new StatsService(notificationStore);

    subsService.addSignalREventHandler(
      'subscriber',
      'GetAll',
      (
        message: {
          Id: string;
          Ip: string;
          Subscriptions: any[];
        }[]
      ) => {
        setConnectedSubsCount(message.length);
      }
    );

    trackerService.addSignalREventHandler(
      'tracker',
      'GetAll',
      (
        message: {
          serial: string;
          ip: string;
          pipelineName: string;
          holeNumber: number;
          isConnected: boolean;
          type: 'string';
          port: number;
        }[]
      ) => {
        setConnectedDataSourcesCount(message.length);
      }
    );

    shotsService.addSignalREventHandler('shot', 'GetTotalShotCount', (totalShotCount: number) => {
      setShotCount(totalShotCount);
    });

    // Add event handler for receiving SignalR messages
    graphService.addSignalREventHandler('graph', 'GetInitialGraphData', (message) => {
      for (var i = 0; i < message.length; i++) {
        statusStore.addData(
          message[i].timestamp,
          message[i].minPipelineProcessingTime,
          message[i].maxPipelineProcessingTime,
          message[i].averagePipelineProcessingTime,
          message[i].messageCount
        );
      }
    });

    graphService.addSignalREventHandler('graph', 'GetNewGraphData', (message) => {
      statusStore.addData(
        message.timestamp,
        message.minPipelineProcessingTime,
        message.maxPipelineProcessingTime,
        message.averagePipelineProcessingTime,
        message.messageCount
      );
    });

    return () => {
      graphService.stopSignalRConnection();
      subsService.stopSignalRConnection();
      trackerService.stopSignalRConnection();
      shotsService.stopSignalRConnection();
    };
  }, [statusStore, notificationStore]);

  return (
    <div>
      <Grid container spacing={3} minHeight={200}>
        <Grid item xs={6}>
          <Typography variant='h5'>{statusStore.configuration.tournamentName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align='right'>
            Latest config applied:&nbsp;
            {statusStore.configuration.created ? dayjs(statusStore.configuration.created).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant='h6' component='h2'>
                Shot Count
              </Typography>
              <Typography color='textSecondary' variant='h4' align='center'>
                <Counter count={shotCount} />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant='h6' component='h2'>
                Connected Data Sources
              </Typography>
              <Typography color='textSecondary' variant='h4' align='center'>
                <Counter count={connectedDataSourcesCount} />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant='h6' component='h2'>
                Connected Subscribers
              </Typography>
              <Typography color='textSecondary' variant='h4' align='center'>
                <Counter count={connectedSubsCount} />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Typography variant='h6' component='h2'>
                Pipeline processing time
              </Typography>
              <Graph />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
});

export default Homepage;
