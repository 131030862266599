import { ErrorHandler, Result } from './Result';
import i18next from 'i18next';

interface ErrorText {
  message: string;
  details: string;
}

interface Error {
  error_code: number;
  error_details: ErrorText;
}

interface ErrorEnvelope {
  errors: Error[];
}

export class AxiosErrorHandler implements ErrorHandler {
  handleError(error: any) {
    let msg = i18next.t('networkError');
    if (error && error.response && error.response.data) {
      const envelope = error.response.data as ErrorEnvelope;
      if (envelope.errors && Array.isArray(envelope.errors) && envelope.errors.length >= 1) {
        msg = envelope.errors[0].error_details.message;
      }
    }

    const newError: Result<any> = {
      errorMessage: msg,
      isError: true,
    };
    return newError;
  }
}
