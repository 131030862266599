import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactDiffViewer from 'react-diff-viewer-continued';
import Grid from '@trackman/web-shared-components/components/Grid';
import Select from '@trackman/web-shared-components/components/Select';
import Button from '@trackman/web-shared-components/components/Button';

type Radar = {
  id: number;
  radarType: string;
  radarPosition: {
    x: number;
    y: number;
    z: number;
  };
};

type Location = {
  id: number;
  name: string;
  radars: Radar[];
  number: number;
};

type TConfig = {
  timestamp: string;
  locations: Location[];
};

const configs: TConfig[] = [];

for (var i = 0; i < 10; i++) {
  let config: TConfig = {
    timestamp: new Date(Date.now() - i * 264 * 1000).toLocaleString(),
    locations: [],
  };

  for (var j = 0; j < 18; j++) {
    let location: Location = {
      id: j,
      number: j + 1,
      name: j + 1 + (Math.random() > 0.5 ? 'T' : 'G'),
      radars: [],
    };

    for (var k = 0; k < 2; k++) {
      let radar: Radar = {
        id: k,
        radarType: 'radarType' + k,
        radarPosition: {
          x: Math.floor(Math.random() * 100),
          y: Math.floor(Math.random() * 100),
          z: Math.floor(Math.random() * 100),
        },
      };
      location.radars.push(radar);
    }

    config.locations.push(location);
  }

  configs.push(config);
}

const Config = observer(() => {
  const [selectedConfigLeft, setSelectedConfigLeft] = useState<number>(configs[1] ? 1 : 0);
  const [selectedConfigRight, setSelectedConfigRight] = useState<number>(0);

  const [selectedLocation, setSelectedLocation] = useState<number>(0);

  const downloadConfig = (config: number) => {
    //TODO: Add download functionality
    alert('Download config ' + config);
  };

  const getConfigs = () => {
    const confLeft = configs[selectedConfigLeft];
    const confRight = configs[selectedConfigRight];

    if (selectedLocation === 0) {
      return [confLeft, confRight];
    } else {
      //return only locations with selected location
      const confLeftLocations = confLeft.locations.filter((location) => location.number === selectedLocation);
      const confRightLocations = confRight.locations.filter((location) => location.number === selectedLocation);
      return [confLeftLocations[0], confRightLocations[0]];
    }
  };

  //Handle config change event
  const handleConfigChange = (val: string, configSide: number) => {
    if (configSide === 0) {
      setSelectedConfigLeft(parseInt(val));
    } else {
      setSelectedConfigRight(parseInt(val));
    }
  };

  const handleLocationChange = (val: string) => {
    setSelectedLocation(parseInt(val));
  };

  return (
    <div>
      <h1>Config comparison</h1>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <Select
            label='Location'
            value={selectedLocation}
            width='100%'
            selectOptions={[
              { value: 0, label: 'All' },
              ...[...Array(18)].map((_, i) => {
                return { value: i + 1, label: i + 1 };
              }),
            ]}
            onChange={(e) => {
              handleLocationChange(e.target.value as string);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label='Select config version'
            value={selectedConfigLeft}
            width='100%'
            name='confVersionLeft'
            selectOptions={configs.map((config, idx) => {
              return {
                value: idx,
                label: config.timestamp + (idx === 0 ? ' (current)' : ''),
              };
            })}
            onChange={(e) => {
              handleConfigChange(e.target.value as string, 0);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label='Select config version'
            value={selectedConfigRight}
            width='100%'
            name='confVersionRight'
            selectOptions={configs.map((config, idx) => {
              return {
                value: idx,
                label: config.timestamp + (idx === 0 ? ' (current)' : ''),
              };
            })}
            onChange={(e) => {
              handleConfigChange(e.target.value as string, 1);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            label='Download'
            intent='secondary'
            onClick={() => {
              downloadConfig(0);
            }}
          />
        </Grid>
        <Grid item xs={6} textAlign={'right'}>
          <Button
            label='Download'
            intent='secondary'
            onClick={() => {
              downloadConfig(1);
            }}
          />
        </Grid>
      </Grid>

      <ReactDiffViewer
        oldValue={JSON.stringify(getConfigs()[0], null, 2)}
        newValue={JSON.stringify(getConfigs()[1], null, 2)}
        splitView={true}
        hideLineNumbers={true}
      />

    </div>
  );
});

export default Config;
