import { useStores } from 'index';
import { ResponsiveContainer, Tooltip, Legend, ComposedChart, Area, Line, XAxis, YAxis } from 'recharts';
import { observer } from 'mobx-react-lite';
import './Graph.scss';
import Colors from '@trackman/web-shared-components/styles/Colors';

export const Graph = observer(() => {
  const ss = useStores().statusStore;

  const labelStyle = {
    className: 'graphLabel',
  };

  const getTime = (date: Date) => {
    const timeStr = [date.getHours(), date.getMinutes(), date.getSeconds()].map((a) => (a < 10 ? '0' + a : a));
    return timeStr.join(':');
  };

  const formatXAxis = (tickFormat: Date) => {
    return getTime(tickFormat);
  };

  return (
    <div className='graphContainer'>
      <ResponsiveContainer width='100%' height={400}>
        <ComposedChart data={ss.points} margin={{ top: 20, right: 20, left: 0, bottom: 5 }}>
          <Legend />
          <Tooltip
            labelFormatter={(label) => {
              let date = new Date(label);
              return getTime(date);
            }}
          />
          <YAxis
            label={{
              value: 'Time (ms)',
              position: 'insideBottom',
              fontSize: 12,
              fill: Colors.darkGrey,
            }}
            tick={{ fill: Colors.darkGrey, fontSize: 12 }}
            axisLine={{ stroke: Colors.darkGrey }}
            tickLine={{ stroke: Colors.darkGrey }}
            padding={{ top: 20, bottom: 20 }}
          />
          <YAxis
            domain={[0, 200]}
            padding={{ top: 20, bottom: 20 }}
            yAxisId='right'
            type='number'
            label={{
              value: 'Messages',
              position: 'insideBottom',
              fontSize: 12,
              fill: Colors.darkGrey,
            }}
            tick={{ fill: Colors.darkGrey, fontSize: 12 }}
            axisLine={{ stroke: Colors.darkGrey }}
            tickLine={{ stroke: Colors.darkGrey }}
            dataKey='messageCount'
            orientation='right'
            stroke='#82ca9d'
          />
          <Line
            dot={{ fill: 'white', r: 15, strokeWidth: 0 }}
            dataKey='pTimeMin'
            name='Min processing time'
            label={labelStyle}
            isAnimationActive={false}
            stroke={Colors.acceptGreen}
            strokeWidth={2}
            yAxisId={0}
          />
          <Line
            dot={{ fill: 'white', r: 15, strokeWidth: 0 }}
            dataKey='pTimeAvg'
            label={labelStyle}
            name='Avg. processing time'
            isAnimationActive={false}
            stroke={Colors.orange}
            strokeWidth={2}
            yAxisId={0}
          />
          <Line
            dot={{ fill: 'white', r: 15, strokeWidth: 0 }}
            dataKey='pTimeMax'
            name='Max processing time'
            label={labelStyle}
            isAnimationActive={false}
            stroke={Colors.dangerRed}
            yAxisId={0}
          />
          <Area
            name='Message count'
            className='msgCountArea'
            isAnimationActive={false}
            dataKey='messageCount'
            yAxisId={'right'}
            fill='#8884d8'
            stroke='#8884d8'
          />
          <XAxis
            tick={{ fill: Colors.darkGrey, fontSize: 12 }}
            axisLine={{ stroke: Colors.darkGrey }}
            tickLine={{ stroke: Colors.darkGrey }}
            dataKey='time'
            tickFormatter={(tick) => formatXAxis(tick)}
            padding={{ left: 15, right: 20 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
});

export default Graph;
