import { makeObservable, runInAction } from 'mobx';

export class StatusStore {
  points: { time: Date; pTimeMin: number; pTimeMax: number; pTimeAvg: number; messageCount: number }[] = [];
  system: { version: string } = { version: '' };
  configuration: {
    id: string | null;
    tournamentName: string | null;
    courseName: string | null;
    courseNumber: number | null;
    created: string | null;
  } = {
      id: null,
      tournamentName: null,
      courseName: null,
      courseNumber: null,
      created: null,
    };
  notificationStore: any;

  constructor(notificationStore: any) {
    makeObservable(this, {
      points: true,
      configuration: true,
      system: true,
    });
    this.notificationStore = notificationStore;

    this.fetchGeneralStatus();
  }

  async fetchGeneralStatus() {
    fetch('/api/system/version')
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          return Promise.reject(response);
        }
      })
      .then((data) => {
        runInAction(() => {
          this.system = { version: data };
        });
      })
      .catch((error) => {
        this.notificationStore.addToast('Error', 'Failed to fetch system version', 'error');
        console.error('Error:', error);
      });

    fetch('/api/pipelinesconfiguration/current')
      .then((response) => response.json())
      .then((data) => {
        runInAction(() => {
          if (data) {
            this.configuration = {
              id: data.id,
              tournamentName: data.tournamentName,
              courseName: data.courseName,
              courseNumber: data.courseNumber,
              created: data.created,
            };
          }
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.notificationStore.addToast('Error', 'Failed to fetch configuration', 'error');
      });
  }

  addData(dateStr: string, pTimeMin: number, pTimeMax: number, pTimeAvg: number, messageCount: number) {
    runInAction(() => {
      let dateStamp = new Date(dateStr);

      if (this.points.length > 20) {
        this.points = this.points.slice(1);
      }

      let point = {
        time: dateStamp,
        pTimeMin: pTimeMin,
        pTimeMax: pTimeMax,
        pTimeAvg: pTimeAvg,
        messageCount: messageCount,
      };

      //If we already have a point for this time, replace it
      let existingPoint = this.points.find((p) => p.time.getTime() === dateStamp.getTime());

      if (existingPoint) {
        this.points = this.points.map((p) => (p.time.getTime() === dateStamp.getTime() ? point : p));
        return;
      } else {
        this.points.push(point);
        return;
      }
    });
  }
}

export default StatusStore;
