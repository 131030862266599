import * as RoutesModule from 'Routes';

import { createContext, useContext } from 'react';

import App from './App';
import { AxiosErrorHandler } from 'services/AxiosErrorHandler';
import { AxiosInstance } from 'axios';
import { AxiosServiceConfig } from 'services/AxiosServiceConfig';
import { AxiosServiceFactory } from 'services/AxiosServiceFactory';
import { BrowserRouter } from 'react-router-dom';
import { ConfigurationService } from 'services/ConfigurationService';
import { NotificationsStore } from 'stores/NotificationsStore';
import { UnitsStore } from 'stores/UnitsStore';
import { StatusStore } from 'stores/StatusStore';
import { createRoot } from 'react-dom/client';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import registerServiceWorker from './registerServiceWorker';
import translationsEn from './locales/en/translation-en.json';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

registerServiceWorker();

type Stores = {
  errorHandler: AxiosErrorHandler;
  configurationService: ConfigurationService;
  apiServiceAxiosInstance: AxiosInstance;
  notificationStore: NotificationsStore;
  statusStore: StatusStore;
  unitsStore: UnitsStore;
};

const StoresContext = createContext<Stores | null>(null);

export const useStores = () => {
  const stores = useContext(StoresContext);
  if (!stores) {
    throw new Error('useStores must be used within a StoreProvider.');
  }
  return stores;
};

const langResources = {
  en: { translation: translationsEn },
};

export const isLangAvailable = (lang: string) => {
  return Object.keys(langResources).includes(lang) ? lang : '';
};

async function RenderApp() {
  const errorHandler = new AxiosErrorHandler();
  const configurationService = new ConfigurationService();
  const serviceFactory = new AxiosServiceFactory(configurationService);
  const apiServiceAxiosInstance = await serviceFactory.createInstance();
  const notificationStore = new NotificationsStore();
  const statusStore = new StatusStore(notificationStore);
  const unitsStore = new UnitsStore();

  const stores: Stores = {
    errorHandler,
    configurationService,
    apiServiceAxiosInstance,
    notificationStore,
    statusStore,
    unitsStore,
  };

  await i18next.use(initReactI18next).init({
    resources: langResources,
    lng: isLangAvailable(navigator.language.slice(0, 2)) || 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

  // Add a request and response interceptor
  AxiosServiceConfig.setDefaultDecorators(apiServiceAxiosInstance, errorHandler);

  root.render(
    <BrowserRouter>
      <StoresContext.Provider value={stores}>
        <App>{RoutesModule.routes}</App>
      </StoresContext.Provider>
    </BrowserRouter>
  );
}

RenderApp();
