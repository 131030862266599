import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactComponent as MegaIcon } from 'assets/images/icons/figma/mega.svg';
import './DataSources.scss';
import { useStores } from 'index';

import StatsService from 'services/StatsService';

//row interface
interface Row {
  id: string;
  ip: string;
  pipelineName: string;
  holeNumber: number | string;
  isConnected: boolean;
  radarType: string;
}

let columns = [
  { field: 'holeNumber', headerName: 'Hole', type: 'number' },
  { field: 'ip', headerName: 'IP / Domain', flex: 1 },
  { field: 'pipelineName', headerName: 'Pipeline Name', flex: 1 },
  { field: 'radarType', headerName: 'Radar type', flex: 1 },
  {
    field: 'isConnected',
    headerName: 'Connection status',
    width: 150,
    renderCell: (params: GridRenderCellParams<any, Row>) => (
      <span className={'conStatusIcon --' + (params.value ? 'connected' : 'disconnected')}>
        <MegaIcon />
      </span>
    ),
  },
];

export const DataSources = observer(() => {
  const [rows, setRows] = useState<Row[]>([]);
  const { notificationStore } = useStores();

  useEffect(() => {
    const trackerService = new StatsService(notificationStore);
    // Start SignalR connection when the component mounts

    trackerService.addSignalREventHandler(
      'tracker',
      'GetAll',
      (
        message: {
          serial: string;
          ip: string;
          pipelineName: string;
          holeNumber: number;
          isConnected: boolean;
          type: 'string';
          port: number;
        }[]
      ) => {
        let updatedRows: Row[] = [];

        message.forEach((source) => {
          const newRow: Row = {
            id: `${source.ip}-${source.type}-${source.holeNumber}`, // We combine the serial and type to create a unique ID in case we have multiple sources with the same serial
            ip: source.ip + ':' + source.port || 'N/A',
            pipelineName: source.pipelineName || 'N/A',
            holeNumber: source.holeNumber || 'N/A',
            isConnected: source.isConnected,
            radarType: source.type || 'N/A',
          };
          updatedRows.push(newRow);
        });

        setRows(updatedRows);
      }
    );

    return () => {
      trackerService.stopSignalRConnection();
    };
  }, [notificationStore]);

  return (
    <div className='tm-dataTable' style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        initialState={{
          sorting: {
            sortModel: [{ field: 'holeNumber', sort: 'asc' }],
          },
        }}
      />
    </div>
  );
});

export default DataSources;
