import { AxiosInstance, AxiosResponse } from "axios";
import { ErrorHandler } from "./Result";

export class AxiosServiceConfig {
  static setDefaultDecorators = (
    axiosInstance: AxiosInstance,
    errorHandler: ErrorHandler,
  ) => {
    const requestDefaultErrorHandler = (error: any) => {
      // Do something with request error
      return Promise.reject(error);
    };

    const responseDefaultErrorHandler = (error: any) => {
      // let errorTransformed = errorHandler.handleError(error);
      return Promise.resolve(error.response);
    };

    const responseDefaultDecorator = (response: AxiosResponse<any>) => {
      if (
        response.headers["content-type"] &&
        response.headers["content-type"].indexOf("application/json") > -1
      ) {
        response.data = {
          isError: false,
          data: response.data,
        };
      }
      return response;
    };

    const requestDefaultDecorator = (request: any) => {
      return Promise.resolve(request);
    };

    // Add a request and response interceptor
    axiosInstance.interceptors.request.use(
      requestDefaultDecorator,
      requestDefaultErrorHandler,
    );
    axiosInstance.interceptors.response.use(
      responseDefaultDecorator,
      responseDefaultErrorHandler,
    );
  };
}
