/*
This store will act as a unit converter taking metric measurements and returning them in the desired format.

US > yds/ft, mph
EU > m, mph
KO > m, km/h
JP > yds, m/s

*/

import convert from 'convert-units';


type MeasurementType = string | undefined;
type UnitFormat = string;

export class UnitsStore {
  getUnitFormats() {
    return [
      { label: 'US', value: 'yds/ft, mph' },
      { label: 'EU', value: 'm, mph' },
      { label: 'KO', value: 'm, km/h' },
      { label: 'JP', value: 'yds, m/s' },
    ];
  }

  getFormattedMetric(value: number, unitFormat: UnitFormat, measurementType: MeasurementType) {
    switch (measurementType) {
      case 'distance':
        return this.getDistance(value, unitFormat);
      case 'speed':
        return this.getSpeed(value, unitFormat);
      case 'angle':
        return this.getAngle(value, unitFormat);
      case 'spin':
        return this.getSpin(value, unitFormat);
      default:
        return {
          value: -1,
          unit: 'N/A',
        };
    }
  }

  getDistance(value: number, unitFormat: UnitFormat) {
    switch (unitFormat) {
      case 'US':
      case 'JP':
        return {
          value: convert(value).from('m').to('yd'),
          unit: 'yd',
        };
      case 'EU':
      case 'KO':
        return {
          value: value,
          unit: 'm',
        };
    }
  }

  getSpin(value: number, unitFormat: UnitFormat) {
    return {
      value: value,
      unit: 'rpm',
    };
  }

  getAngle(value: number, unitFormat: UnitFormat) {
    return {
      value: value,
      unit: '°',
    };
  }

  getSpeed(value: number, unitFormat: UnitFormat) {
    switch (unitFormat) {
      case 'US':
      case 'EU':
        return {
          value: convert(value).from('m/s').to('m/h'),
          unit: 'mph',
        };
      case 'KO':
        return {
          value: convert(value).from('m/s').to('km/h'),
          unit: 'km/h',
        };
      case 'JP':
        return {
          value: convert(value).from('m/s').to('m/s'),
          unit: 'm/s',
        };
    }
  }
}

export default UnitsStore;
