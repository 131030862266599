import "./Sidebar.scss";
import MenuList from "@mui/material/MenuList";
import { ReactComponent as HomeIcon } from "assets/images/icons/figma/home.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/figma/settings.svg";
import { ReactComponent as TagIcon } from "assets/images/icons/figma/tag.svg";
import { ReactComponent as TrajectoryIcon } from "assets/images/icons/figma/trajectory.svg"
import { ReactComponent as VisualDataIcon } from "assets/images/icons/figma/visual-data.svg";
import { Link, useLocation } from "react-router-dom";
import { DefaultRoutes } from "Routes";
import { MenuItems } from "constants/MenuItems";
import { Typography } from "@mui/material";
import { MenuItem } from "@trackman/web-shared-components/components/Menu/MenuItem";

export const Sidebar = () => {
  const location = useLocation();

  const menuItem = (name: string, Icon: JSX.Element, routes?: string[]) => {
    let className = () => {
      let cName = 'sideBar__listItem';

      if (
        routes &&
        routes.some((r) => {
          return location.pathname.toLowerCase() === r.toLowerCase();
        })
      ) {
        cName += ' --userIsHere';
      }

      return cName;
    };

    return (
      <MenuItem className={className()}>
        <Link to={routes ? routes[0] : '#'} className='sideBar__link'>
          {Icon}
          <Typography component={'span'}>{name}</Typography>
        </Link>
      </MenuItem>
    );
  };

  return (
    <>
      <div className='sideBar'>
        <MenuList className='sideBar__list'>
          {menuItem(MenuItems.FrontPage, <HomeIcon />, [DefaultRoutes.FrontPage, '/'])}
          {menuItem(MenuItems.DataSources, <VisualDataIcon />, [DefaultRoutes.DataSources])}
          {menuItem(MenuItems.ShotViewer, <TrajectoryIcon />, [DefaultRoutes.ShotViewer])}
          {menuItem(MenuItems.Subscribers, <TagIcon />, [DefaultRoutes.Subscribers])}
          {menuItem(MenuItems.Config, <SettingsIcon />, [DefaultRoutes.Config])}
        </MenuList>
      </div>
    </>
  );
};
